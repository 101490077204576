import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useSound from 'use-sound';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: 'auto',
        maxWidth: '300px',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);






const EndRound: React.FC<any> = (props) => {
    const socket = props.socket
    const game = props.game
    const classes = useStyles();
    const [sfxIncorrect] = useSound('./sounds/wrong.ogg');
    const [sfxCorrect1] = useSound('./sounds/correct1.mp3');
    const [sfxCorrect2] = useSound('./sounds/correct2.wav');

 

    useEffect(() => {
  
        console.log('endround useEffect - render');
        if(game.isBuddy) return
        console.log(props.clientId);
        console.log( game.players)
        let self: any
        self = game.players.filter((player: any) => player.id === props.clientId)[0];
        console.log(self);

        if(self.isCorrect) {
            Math.random() < 0.5 ? sfxCorrect1() : sfxCorrect2()
            console.log('correct')
        } else {
         sfxIncorrect()
         console.log('incorrect')
        }

        return() => {
           
            console.log('exiting endround');
        }
    }, [sfxCorrect1, sfxCorrect2, sfxIncorrect]);




    const nextRound = () => {
        console.log('nextRound');
        //sfxAnswer()
        var payload = {type: 'nextRound', gameId: game.id };
        socket.emit('buddyGame', payload);
    }

   

    return (
        <div className={classes.root} >

            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0} >
                <Grid item xs={12}>
                       <p className="result-header">Result</p>
                </Grid>
                <Grid item xs={12}>
                    <p className="result-question">{game.question.question}</p>
                </Grid>
                {game.correctAnswer && game.correctAnswer.optionId !== 0 &&
                    <Grid item xs={12}>
                    <p className="what-would-they-say">{game.buddy.name} chose...</p>
                    {game.correctAnswer && 
                        <p className="the-answer">{game.correctAnswer.optionText}</p>
                    }
                    </Grid>
                } 
                {game.correctAnswer && game.correctAnswer.optionId === 0 &&
                    <Grid item xs={12}>
                    <p className="what-would-they-say">{game.buddy.name}</p>
                    {game.correctAnswer && 
                        <p className="the-answer">{game.correctAnswer.optionText}</p>
                    }
                    </Grid>
                }

                {game.correctAnswer && game.players && game.players.map((player:any) => {
                return player.isBuddy ? '' : ( 
                    <Grid item xs={12} key={player.id}>
                        {player.isCorrect && 
                            <p className="correct">{player.name} - <span>Correct!  +{player.points} points</span></p>
                        }
                        {!player.isCorrect && player.answer && 
                            <p className="incorrect">{player.name} - {game.correctAnswer.optionId !== 0 && <span>Wrong! - </span>}<span>{player.answer.optionText}</span></p>
                        }
                        {!player.isCorrect && !player.answer && 
                            <p className="incorrect">{player.name} - <span>No answer</span></p>
                        }
                        </Grid>)
                    }
                )}
            
                <Grid item xs={12}><p></p><p></p></Grid>   
                {game.isBuddy && 
                    <Grid item xs={12}>
                        <Button onClick={nextRound} className="play-button" variant="outlined" size ="large">Next</Button>
                    </Grid>
                }
            </Grid>
        



        </div>
    )


}
export default EndRound;