import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import confetti from 'canvas-confetti';

//import Sound from 'react-sound';
import useSound from 'use-sound';

import { Fade, Rotate, Jello } from 'react-awesome-reveal';
//import { socket } from '../common/socket.service';
import { useStore } from "../common/store";




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: 'auto',
        maxWidth: '300px',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);

interface remainingTime {
    key: string;
    value: {}
}
interface IProps {
    remainingTime: any;
    // any other props that come into the component
}

const RenderTime = ({remainingTime}: IProps) => {

    const currentTime = useRef(remainingTime);
    const prevTime = useRef<any>(null);
    const isNewTimeFirstTick = useRef(false);
    //const [, setOneLastRerender] = useState(0);
  
    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }
    let timerx: any;
    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) { // not sure this does much, but causes render problem
     // timerx = setTimeout(() => {
    //    setOneLastRerender(val => val + 1);
    //  }, 20);
    }
  
    const isTimeUp = isNewTimeFirstTick.current;

    useEffect(() => {
        console.log('clock useEffect - render');
        return() => {
            console.log('exiting clock');
            clearTimeout(timerx);
        }
    }, []);
  
    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          {remainingTime}
        </div>
        {prevTime.current !== null && (
          <div
            key={prevTime.current}
            className={`time ${!isTimeUp ? "down" : ""}`}
          >
            {prevTime.current}
          </div>
        )}
      </div>
    );
  };




const Answering: React.FC<any> = (props) => {
    const socket = props.socket
    const game = props.game
    const [sfxAnswer] = useSound('./sounds/beep.wav');



    const classes = useStyles();
    let history = useHistory();

    

    



    const [optionsClass, setOptionsClass] = useState<any>(['option-button','option-button','option-button','option-button']);

   //   setOptionsClass((optionsClass: any) => [...optionsClass]);

    const [countingDown, setCountingDown] = useState(false);
    /*
    const [choiceSound, setChoiceSound] = useState<any>('STOPPED');
    const [wrongSound, setWrongSound] = useState<any>('STOPPED');
    const [correct1Sound, setCorrect1Sound] = useState<any>('STOPPED');
    const [correct2Sound, setCorrect2Sound] = useState<any>('STOPPED');
    const [failedSound, setFailedSound] = useState<any>('STOPPED');
    const [winnerSound, setWinnerSound] = useState<any>('STOPPED');
*/
    

    useEffect(() => {
        console.log('answering useEffect - render');
        resetButtonsAndSounds();
        randomiseButtonOrder()
        setCountingDown(true);
        return() => {
            console.log('exiting answering');
        }
    }, []);


    const randomiseButtonOrder = () => {
        for(let i = game.question.options.length-1; i > 0; i--){
            const j = Math.floor(Math.random() * i)
            const temp:any = game.question.options[i]
            game.question.options[i] = game.question.options[j]
            game.question.options[j] = temp
          }
    }

    const setAnswer = (answer: any) => {
        console.log(answer);
        sfxAnswer()
       // setOptionsClass([...optionsClass, 'option-button-selected']);
       let newOptions = ['option-button','option-button','option-button','option-button'];

        setOptionsClass(
            newOptions.map((item:any, i:any) => 
                i === answer-1
                ? [...item, 'option-button-selected']
                : item 
        ))

        console.log('answer', answer);
        var payload = {type: 'setAnswer', gameId: game.id, answer: answer };
        console.log(payload)
        socket.emit('buddyGame', payload);
      //  setChoiceSound('PLAYING');
        setTimeout(() => {
           // setChoiceSound('STOPPED');
        }, 500)
    }

    const resetButtonsAndSounds = () => {
       // setCorrect1Sound('STOPPED');
      //  setCorrect2Sound('STOPPED');
      //  setWrongSound('STOPPED');
      //  setFailedSound('STOPPED');
      //  setWinnerSound('STOPPED');
        setOptionsClass(['option-button','option-button','option-button','option-button']);
    }
    const nextRound = () => {
        console.log('nextRound');
        var payload = {type: 'nextRound', gameId: game.id };
        socket.emit('buddyGame', payload);
    }
    const playAgain = () => {
        console.log('playAgain');
        var payload = {type: 'backToLobby', gameId: game.id };
        socket.emit('buddyGame', payload);
    }

    const startConfetti = () => {
        var duration = 2 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min: number, max: number) {
            return Math.random() * (max - min) + min;
        }

        var interval: any = setInterval(function() {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
        }, 250);
        console.log('startConfetti');
    }



    return (
        <div className={classes.root} >
           

{/*
                    PLAYING ROUND
*/}
       
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0} >
                    <Grid item xs={12}><p></p></Grid>
                    
                    <Grid item xs={12}>
                        <div className="countdown-clock">
                            <CountdownCircleTimer  isPlaying={countingDown} duration={game.countdownTime} colors={[ ['#004777', 0.33], ['#F7B801', 0.33], ['#A30000', 0.33], ]} 
                            size={80} strokeWidth={14} strokeLinecap="square" trailColor="#ff0000" >
                            {RenderTime}
                            </CountdownCircleTimer>
                        </div>
                    </Grid>
                    {!game.isBuddy && <Grid item xs={12}>
                        <p className="what-would-they-say">What would {game.buddy.name} say?</p>
                    </Grid>}
                    <Grid item xs={12}><Rotate>
                        <p className="question">{game.question.question}</p></Rotate>
                    </Grid>
                    <Grid item xs={12}>
                        {game.question.options && game.question.options.map((option: any) => {
                            return (
                            <Jello key={option.optionId}>
                              <Button onClick={e => setAnswer(option.optionId)} className={optionsClass[option.optionId-1]} color="primary" 
                              value={option.optionId} variant="contained" >{option.optionText}</Button>
                            </Jello>
                            )
                        })}

                    </Grid>
                </Grid>
           






</div>
    )


}
export default Answering;