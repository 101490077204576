import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { createGame } from '../common/socket.service';

import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

import { useStore } from "../common/store";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: 'auto',
        maxWidth: '300px',

      },
      '& > MuiButton-outlinedSecondary': {
        backgroundColor: 'white',
      },

      'MuiFormControl-root': {
        backgroundColor: 'white',
      },
      '& > MuiInputLabel-outlined': {
        backgroundColor: 'white',
      }

      
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);


const Host: React.FC<any> = (props) => {
    const classes = useStyles();
    let history = useHistory();
  //  const {state, dispatch} = useStore();
    const socket = props.socket
    const game = props.game


    const socketGameReceived = (game:any) => {
        console.log('game Websocket event received!', game);
        socket.off('game', socketGameReceived);
        props.newGame(game);
    }


    useEffect(() => {
        console.log('host useEffect - render');      
        socket.on('game', socketGameReceived);

        return() => {
          console.log('exiting host');
        }

}, []);




    const hostNewGame  = (( values: { name: any; }, { setSubmitting }: any) =>  { 
      console.log('createGame', values);
      createGame(values.name, 'buddy');
     // createGame(values.name, 'voting');
    });




        const validationSchema1 = Yup.object().shape({ 
            name: Yup.string().required('You must enter your name!'),
        });



    return (
        <div className={classes.root} >


    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}  >
        <Grid item xs={12}>
          <Formik initialValues={{ 'name': '' }} validationSchema={validationSchema1} onSubmit={hostNewGame}  >
            {({ errors, touched, isSubmitting, setFieldValue }) => (

              <Form className="join-form">
                  <Field label="Your Name" className="input" component={TextField} name="name" variant="outlined" />
                  <Button type="submit" className="play-button"  variant="contained">Host New Game</Button>
              </Form>
          )}
          </Formik>
        </Grid>
    
</Grid>






</div>
)






}
export default Host;