import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useSound from 'use-sound';
import confetti from 'canvas-confetti';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: 'auto',
        maxWidth: '300px',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);






const EndGame: React.FC<any> = (props) => {
    const socket = props.socket
    const game = props.game
    const classes = useStyles();
    const [sfxFailed] = useSound('./sounds/failed.wav');
    const [sfxWinner] = useSound('./sounds/winner.wav');





    useEffect(() => {
        console.log('endgame useEffect - render');

        startConfetti();
        let self: any
        self = game.players.filter((player: any) => player.id === props.clientId)[0];
        let isWinner = game.winners.filter((win: any) => win.id === props.clientId)[0];
        console.log(self);
        if (self.isBuddy) return
        if(isWinner) {
            sfxWinner()
        } else {
            sfxFailed()
        }

        return() => {
            console.log('exiting endgame');
        }
    }, [sfxFailed, sfxWinner ]);


    
    const startConfetti = () => {
        var duration = 2 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        function randomInRange(min: number, max: number) {
            return Math.random() * (max - min) + min;
        }

        var interval: any = setInterval(function() {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
        }, 250);
        console.log('startConfetti');
    }


    const playAgain = () => {
        console.log('playAgain');
        var payload = {type: 'backToLobby', gameId: game.id };
        socket.emit('buddyGame', payload);
    }



    return (
        <div className={classes.root} >

<Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0} >
                 <Grid item xs={12}>
                        <h3>GAME OVER</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <h2>The winner is...</h2>
                    </Grid>
                    {game.winners && game.winners.length === 0 && 
                        <Grid item xs={12}>
                            <p className="winner">No Winners!</p>
                        </Grid>
                    }
                    {game.winners && game.winners.map((winner:any) => {
                        return  ( 
                            <Grid item xs={12} key={winner.id}>
                                <p className="winner">{winner.name} - <span>{winner.score} points</span></p>
                            </Grid>
                        )
                        })}
                    <Grid item xs={12}>
                        <h3>Scores</h3>
                    </Grid>
                    {game.players && game.players.map((player:any) => {
                        return  ( 
                            <Grid item xs={12} key={player.id}>
                                <p className="player">{player.name} - <span>{player.score} points</span></p>
                            </Grid>
                        )
                        })}
                    <Grid item xs={12}><p></p><p></p></Grid>
                    {game.isBuddy && 
                        <Grid item xs={12}>
                            <Button onClick={playAgain} className="play-buttton"  variant="outlined" size ="large">Play Again?</Button>
                        </Grid>
                    }
                </Grid>
            status:{game.status}



        </div>
    )


}
export default EndGame;