import React, { useEffect, useState } from 'react';
import {Router, Switch, Route, useLocation} from "react-router-dom";

import {withRouter, RouteComponentProps} from "react-router";

import { createBrowserHistory } from "history";
import './App.scss';

import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {  initiateSocket as socketServiceInitiate, disconnectSocket } from './common/socket.service';



import Join from './joinhost/join';
import Host from './joinhost/host';
import Lobby from './joinhost/lobby';
import Answering from './joinhost/answering';
import EndRound from './joinhost/endround';
import EndGame from './joinhost/endgame';

interface Props {
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: 'auto',
        maxWidth: '300px',

      },
      '& > MuiButton-outlinedSecondary': {
        backgroundColor: 'white',
      },

      'MuiFormControl-root': {
        backgroundColor: 'white',
      },
      '& > MuiInputLabel-outlined': {
        backgroundColor: 'white',
      }

      
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);



const App: React.FC<any> = (props) => {
  const classes = useStyles();
  const history = createBrowserHistory();
  //client should be able to switch between local & remote if local client, but always be remote when remote
  const environment = process.env.REACT_APP_ENV;  //  'development' or 'production'
  console.log("app start", environment);

  const [clientId, setClientId] = useState<any>(null);
  const [socket, setSocket] = useState<any>(null);
  const [game, setGame] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  
 
  //console.log(useLocation().search);

  useEffect(() => {
    console.log('app useeffect new socket')
    let socket = socketServiceInitiate();
    setSocket(socket)
    socket.on('connect', () => {
      //console.log('setting clientId', socket.id)
      setClientId(socket.id);
      //console.log('clientId', clientId, socket.id)
      setStatus('waitingToJoin')
    });
    return() => {
    }
  }, []);

  useEffect(() => {
    console.log('client is now', clientId);
    setClientId(clientId);
}, [clientId]);
 

  const socketAppGameReceived = (game:any) => {
    console.log('socketAppGameReceived', game);
    updateGame(game);
  }

  const newGame = (newGame:any) => {
    console.log('clientId', clientId);
    console.log('newGame', newGame)
    newGame.buddy = getBuddy(newGame)
    console.log('buddy', newGame.buddy);
    newGame.isBuddy = false;
    if(newGame.buddy) newGame.isBuddy = newGame.buddy.id === clientId;
    console.log('am I buddy', newGame.isBuddy, clientId);

    setGame(newGame);
    console.log(newGame)
    setStatus(newGame.status)
    socket.on('game', socketAppGameReceived);  // only listen once - called when you host or join a game
  }

  const updateGame = (updatedGame:any) => {
    console.log('updateGame', updatedGame)
    if(!updatedGame.players) {
      setGame(updatedGame);
      setStatus(updatedGame.status)
      return
    }
    console.log('updateGame', updatedGame)
    console.log('updateGame', game)
    updatedGame.buddy = getBuddy(updatedGame)
    console.log('buddy', updatedGame.buddy, clientId);
    updatedGame.isBuddy = false;
    if(updatedGame.buddy) updatedGame.isBuddy = updatedGame.buddy.id === clientId;
    console.log('am I buddy', updatedGame.isBuddy.id, clientId);
    console.log(updatedGame.buddy.id === clientId)
    if(updatedGame.question && updatedGame.question.options) {
      if(updatedGame.type === 'voting') {
        updatedGame.question.options = updatedGame.question.options.filter((option: { optionClientId: any; }, index: any) => {
          console.log(option.optionClientId,'-', clientId )
          return option.optionClientId != clientId;
        });
      }
    }
    setGame(updatedGame);
    setStatus(updatedGame.status)
  }

  const getBuddy = (game:any) => {
    return game.players.filter((player: any) => player.isBuddy === true)[0];
  }


  const leaveGame = () => {
    console.log('leave game')
    setStatus(null)
    setGame(null)
    setSocket(null)
    disconnectSocket()
    window.location.reload(false) //  reload page, so back to join and host
  }

  const backToMenu = () => {
    console.log('backToMenu')
    window.open('https://gameofwins.com', '_self' );
  }


  return (
  
    <div className="App">

      <div className="App-header"></div>
      <Router history={history}>
      <div className={classes.root} >
        <h1>The Buddy Game</h1>
   {/* {clientId} - {game &&  (typeof game.isBuddy !== 'undefined') && game.isBuddy.toString()} */}

      {status === 'waitingToJoin' &&
        <div>
          <Join socket={socket} game={game} newGame={newGame}></Join>
          <Host socket={socket} game={game} newGame={newGame}></Host>
        </div>
      }


      {status === 'inLobby' && (typeof game.isBuddy !== 'undefined') &&
        <div>
          <Lobby socket={socket} game={game} updateGame={updateGame}></Lobby>
        </div>
      }

      {game && game.status === 'answering' &&
        <div>
          <Answering socket={socket} game={game} updateGame={updateGame}></Answering>
        </div>
      }

      {game && game.status === 'endRound' && (typeof clientId !== 'undefined') &&
        <div>
          <EndRound socket={socket} game={game} clientId={clientId} updateGame={updateGame}></EndRound>
        </div>
      }

      {game && (typeof game.isBuddy !== 'undefined') && game.status === 'endGame' &&
        <div>
          <EndGame socket={socket} game={game} clientId={clientId} updateGame={updateGame}></EndGame>
        </div>
      }

      {game && game.status !== 'answering' && game.status !== 'endRound' && 
        <div>
           <Button  onClick={leaveGame} className="back-button" variant="outlined">Leave Game</Button>
        </div>
      }

      {!game &&
        <div>
           <Button onClick={backToMenu} className="back-button" variant="outlined">Back to menu</Button>
        </div>
      }

</div>
</Router>
    </div>

  );
}

export default App;
