import io from 'socket.io-client';


//client should be able to switch between local & remote if local client, but always be remote when remote
const environment = process.env.REACT_APP_ENV;  //  'development' or 'production'
  // httpsOptions stopped working locally in all browsers apart from chrome on pc. All others say NET::ERR_CERT_AUTHORITY_INVALID 
  // so removed https from local
let socketHost = 'http://192.168.0.12:30000';  //  dev - was https
//let socketHost = 'https://gameofwins.com:30000';  // dev
if(environment == 'production') socketHost = 'https://gameofwins.com:30000'  //  if running on vercel or anywhere remote - must point to remote API

console.log('socket.service', environment, socketHost);

/*
const socket = io(socketHost) // called from app.tsx
console.log('CREATING NEW SOCKETSERVICE', socket);
export { socket }
*/

let socket: any;


export const initiateSocket = () => { // not used
  socket = io(socketHost);
  console.log(`Connecting socket...`);
  return socket;
}
export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if(socket) socket.disconnect();
}

export const createGame = (playerName:string, gameType: string) => {
  console.log('Create game ...', playerName, gameType);
  if (socket) {
    socket.emit('createGame', { playerName: playerName, gameType: gameType});
  }
}
export const joinGame = (gameId: string, name: string) => {
  console.log('Join game ...', gameId, name)
  if (socket && gameId) {
    socket.emit('joinGame', {gameId: gameId, name: name});
  }
}

 export const subscribeToGame = (cb: any) => {
  if (!socket) return(true);

  socket.on('game', (msg: any) => {
    console.log('game Websocket event received!', msg);
   // return cb(null, msg);
   return cb(null, msg);
  });
}


export const subscribeToChat = (cb: any) => {
  if (!socket) return(true);

  socket.on('chatx', (msg: any) => {
    console.log('subscribeToChat Websocket event received!', msg);
   // return cb(null, msg);
   return cb(null, msg);
  });
}

export const sendMessage = (room: string, message: any) => {
  if (socket) {
   // room = '12x34';
    console.log('sending', room, message);
    socket.emit('chatx', { room, message });
    //   socket.emit('chatx', { message, room });
  } else {
    console.log('no socket');
  }
}
export const subscribeToRoom = (room: string, cb: any) => {
  if (!socket) return(true);

  socket.on(room, (msg: any) => {
    console.log('subscribeToRoom Websocket event received!', msg);
    return cb(null, msg);
  });
}



/*

export class SocketService {
    public socket: SocketIOClient.Socket = {} as SocketIOClient.Socket;

    public init (): SocketService {
      console.log('init SocketService', this);
      
   //   const dispatch = useDispatch();
      this.socket = io(socketHost);
   
 
      this.socket.on('msgToClient', (data: any) => {
        console.log('received msg socket', data);
    //    dispatch(updateInventory(data.data));
      });
      
      return this;
    }


    export const initiateSocket = (room) => {
      socket = io('http://localhost:3000');
      console.log(`Connecting socket...`);
      if (socket && room) socket.emit('join', room);
    }
    expor

     public disconnect (): void {
         console.log('disconnect sockets');
        this.socket.disconnect();
       }


     public send (message: SocketServerRequest): void {
        this.socket.emit('msgToServer', message, (data: any) => console.log(data));
        //socket.emit('events', { name: 'Nest' }, data => console.log(data));
      }


}
*/