import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import useSound from 'use-sound';

//import  initiateSocket from '../common/socket.service'
//import {  initiateSocket as socketServiceInitiate, disconnectSocket, subscribeToChat, sendMessage, subscribeToRoom, createRoom, joinRoom } from '../common/socket.service';
//import {GameProvider} from '../context/game.context';
//import SocketContext from '../common/socket.context'
import GameContext from '../context/game.context';

import { useStore } from "../common/store";




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);



const Lobby: React.FC<any> = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const socket = props.socket
    const game = props.game
    const [sfxAnswer] = useSound('./sounds/beep.wav');




  useEffect(() => {
    console.log('lobby useEffect - render');
    return() => {
      console.log('exiting lobby');
      //    socket.off('games', socketGamesReceived);
    }
  }, [])

    
const setBuddy = (id: string) => {
  console.log(id);
  sfxAnswer()
  var payload = {type: 'setBuddy', gameId: game.id, clientId: id};    // need to send clientId as any player can pick someone else to be buddy
  console.log(payload);
  socket.emit('buddyGame', payload);
}



const startGame = () => {
//  console.log('startgame', state);
  var payload = {type: 'startGame', gameId: game.id,};
   socket.emit('buddyGame', payload);
}


    return (
   <div>
     <a data-testid="email" href={"mailto:" + props.email}>
          email
        </a>

    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0} >
    <Grid item xs={12}><h3>Your game code is: {game.id}</h3></Grid>
  
    <Grid item xs={12}><h3>- PLAYERS -</h3></Grid>


    {game.players && game.players.map((player:any, index: number) =>
      <Grid  key={player.id} item xs={12}>
        <Button onClick={e => setBuddy(e.currentTarget.value)} className="players-in-lobby" value={player.id} variant="outlined"
          >{player.totalPoints} - {player.name}&nbsp;&nbsp;&nbsp;{player.isBuddy && <span>(BUDDY)</span>}</Button>
          {/*<Button onClick={e => kickPlayer(e.currentTarget.value)} className="kick-player" value={player.id} variant="outlined"
          >X</Button>*/}
      </Grid>

    )}
    <Grid item xs={12}><p className="copytoclipboard">Tap a player to make them the buddy!</p></Grid>
     <Grid item xs={12}><p></p></Grid>
       {/* <Grid item xs={12}><p>Buddy? {(typeof game.isBuddy !== 'undefined') && game.isBuddy.toString()}{game.id}</p></Grid> */}
    {game.isBuddy && 
    <Grid item xs={12}>
      <Button  onClick={startGame} className="play-button" size="large" variant="contained">Start Game</Button>
    </Grid>
    }
    <Grid item xs={12}><p></p></Grid>
    <Grid item xs={12}><p></p></Grid>

    </Grid>




   </div>

    )


}
export default Lobby;