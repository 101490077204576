import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { joinGame } from '../common/socket.service';

import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

import { useStore } from "../common/store";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: 'auto',
        maxWidth: '300px',

      },
      '& > MuiButton-outlinedSecondary': {
        backgroundColor: 'white',
      },

      'MuiFormControl-root': {
        backgroundColor: 'white',
      },
      '& > MuiInputLabel-outlined': {
        backgroundColor: 'white',
      }

      
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    
  }),
);

const Join: React.FC<any> = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const socket = props.socket
    const game = props.game

console.log(props);

  
    const socketGameReceived = (game:any) => {
        console.log('socketGameReceived', game);
        socket.off('game', socketGameReceived);
        props.newGame(game);
       // if(action.type === 'startGame') {
         //   history.push('./play');
        //    dispatch({type: "setStatus", status: 'waitingToStart'})
       // }
    }



    useEffect(() => {
        console.log('join useEffect - render');      
     //   socket.on('action', socketActionReceived);
      //  socket.on('players', socketPlayersReceived);
        //can't go here otherwise all players automatically go into same room when host creates one socket.on('room', socketRoomReceived);

        socket.on('game', socketGameReceived);

        return() => {
          console.log('exiting join');
        
      //    socket.off('players', socketPlayersReceived);
       //   socket.off('action', socketActionReceived);
        }
    //}, [game.id]);
}, []);



    const joinExistingGame  = (( values: { gameId: any; name: any; }, { setSubmitting }: any) =>  { 
        console.log('joinRoom', values.gameId);
     //   socket.on('players', socketPlayersReceived);
    //    socket.on('room', socketRoomReceived);
       // dispatch({type: "setNameAndIsBuddy", name: values.name,  isBuddy:false})
        joinGame(values.gameId, values.name);

    });

  
        const validationSchema2 = Yup.object().shape({ 
            name: Yup.string().required('You must enter your name!'),
            gameId: Yup.string().required('You need a 4 digit game code to enter a game!'),
        });


    return (
        <div className={classes.root} >





<div >
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}  >
        <Grid item xs={12} >
          <Formik initialValues={{ 'gameId': '', 'name': '' }} validationSchema={validationSchema2} onSubmit={joinExistingGame}  >
            {({ errors, touched, isSubmitting, setFieldValue }) => (

              <Form className="join-form">
                  <Field label="Your Name" component={TextField} className="input" name="name" variant="outlined" />
                  <Field label="Game Code (4 digits)" component={TextField} className="input" name="gameId" variant="outlined" />
              <Button type="submit" className="play-button"  variant="contained">Join Existing Game</Button>
            </Form>
          )}
          </Formik>
        </Grid>

     </Grid>
</div>









</div>
)






}
export default Join;